import React, { useEffect, useRef } from 'react'
import Webcam from 'react-webcam'

const videoConstraints = {
	width: 540,
	facingMode: 'environment',
}

const Camera = () => {
	const webcamRef = useRef<any>(null)
	const [url, setUrl] = React.useState(null)

	const capturePhoto = React.useCallback(async () => {
		const imageSrc = webcamRef.current.getScreenshot()
		const res = await fetch(imageSrc)
		const buf = await res.arrayBuffer()
		const file = new File([buf], 'image', { type: 'image/png' })
		console.log(file)
		setUrl(imageSrc)
	}, [webcamRef])
	const onUserMedia = (e) => {
		console.log(e)
	}
	return (
		<>
			<div>
				<div className='row'>
					<div className='col-6'>
						<Webcam
							ref={webcamRef}
							screenshotFormat='image/png'
							videoConstraints={videoConstraints}
							onUserMedia={onUserMedia}
							mirrored={true}
						/>
					</div>
					<div className='col-6'>
						{url && (
							<div>
								<img src={url} alt='Screenshot' />
							</div>
						)}
					</div>
					<div className='col-6'>
						<button className='btn btn-primary' onClick={capturePhoto}>
							Capture
						</button>
					</div>
					<div className='col-6'>
						<button className='btn btn-primary' onClick={() => setUrl(null)}>
							Re-Take
						</button>
					</div>
				</div>
			</div>
		</>
	)
}

export default Camera
